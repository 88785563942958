import * as R from 'ramda';
import { equals, isNil } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormNames } from '@cappex/constants';
import { StudentType } from '../util/student/studentDataUtil';
import { FormContext } from '../util/validation/form';
import RadioChipSelector, { ChipColumnsDefinition, RadioOption } from './RadioChipSelector';
import { STUDENT_TYPE_ID_HEAP_LABEL } from '../constants/heap';
import StudentContext from '@util/studentContext';

export const STUDENT_TYPE_SELECT_OPTIONS: RadioOption[] = [
	{ displayValue: 'High school student', value: StudentType.HIGH_SCHOOL },
	{ displayValue: 'College student', value: StudentType.COLLEGE },
	{ displayValue: 'Not in school', value: StudentType.NOT_IN_SCHOOL },
];

export type StudentTypeSelectFunctions = {
	studentType: StudentType;
	function: () => void;
};

export type StudentTypeSelectProps = {
	title?: string;
	defaultStudentTypeId?: StudentType;
	options: RadioOption[];
	disabledStudentTypes?: StudentType[];
	studentTypeFunctions?: StudentTypeSelectFunctions[];
	chipColumns?: ChipColumnsDefinition;
	gutter?: boolean;
	isDefault?: boolean;
};

const EMPTY_FUNCTION = () => {};
const EMPTY_LIST = [];
const DEFAULT_CHIP_COLUMNS: ChipColumnsDefinition = { xs: 7 };

const StudentTypeSelect: FC<StudentTypeSelectProps> = ({
	title = 'Join as a',
	defaultStudentTypeId,
	options = STUDENT_TYPE_SELECT_OPTIONS,
	disabledStudentTypes = EMPTY_LIST,
	studentTypeFunctions = EMPTY_LIST,
	chipColumns = DEFAULT_CHIP_COLUMNS,
	gutter,
	isDefault,
}) => {
	const { student } = useContext(StudentContext);
	const { getFormValues } = useContext(FormContext);

	const undefNullOrEmpty = R.anyPass([isNil, equals('')]);

	const { [FormNames.studentTypeId]: studentType } = getFormValues();

	const studentTypeOptions: RadioOption[] = useMemo(
		() =>
			R.map(
				(option: RadioOption) => {
					const index = R.findIndex(R.propEq('studentType', option.value))(studentTypeFunctions);
					return {
						...option,
						onSelect: index === -1 ? undefined : studentTypeFunctions[index].function,
					};
				},
				R.filter((option: RadioOption) => !R.includes(option.value, disabledStudentTypes), options)
			),
		[options, disabledStudentTypes, studentTypeFunctions]
	);

	const { getValue, setFormValue } = useContext(FormContext);
	const [hasSetDefaultData, setHasSetDefaultData] = useState(false);
	const studentTypeFormValue = getValue(FormNames.studentTypeId);
	const formStudentType = studentTypeFormValue?.[FormNames.studentTypeId];
	const studentTypeId = formStudentType || defaultStudentTypeId;

	useEffect(() => {
		if (!hasSetDefaultData && !R.isEmpty(studentTypeFormValue)) {
			setHasSetDefaultData(true);
			if ((isDefault && R.isNil(formStudentType)) || !isDefault) {
				setFormValue(FormNames.studentTypeId, studentTypeId);
			}
		}
	}, [
		studentTypeId,
		hasSetDefaultData,
		setFormValue,
		defaultStudentTypeId,
		studentTypeFormValue,
		isDefault,
		formStudentType,
	]);

	return (
		<RadioChipSelector
			title={title}
			options={studentTypeOptions}
			radioName={FormNames.studentTypeId}
			complete={EMPTY_FUNCTION}
			reverse={EMPTY_FUNCTION}
			chipColumns={chipColumns}
			gutter={gutter}
			disableChips={!undefNullOrEmpty(student.studentTypeId) && !undefNullOrEmpty(studentType)}
			onChange={val => {
				window?.heap?.addUserProperties?.({ [STUDENT_TYPE_ID_HEAP_LABEL]: val });
			}}
		/>
	);
};

export default StudentTypeSelect;
