import * as R from 'ramda';
import { equals, isNil } from 'ramda';
import React, { ChangeEvent, FC, useContext, useMemo, useRef, useState } from 'react';
import { FormNames } from '@cappex/constants';
import { AutomationNameDefault } from '@common/util/automation';
import {
	maxDateCriteria,
	minDateCriteria,
	noLeadingZeroYearCriteria,
} from '@common/util/validation/birthdate';
import {
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	Select,
	TextField,
	TextFieldProps,
	Typography,
} from '@material-ui/core';
import useFormValidation from '@util/hooks/useFormValidation';
import requiredFieldMessage from '@util/validation/constants';
import { FormContext, FormFields } from '@util/validation/form';
import { styled } from '@cappex/theme';
import { SubFormContext } from './BaseValidationForm';
import StudentContext from '@util/studentContext';

interface Props {
	id: string;
	label: string;
	name?: string;
	defaultValue?: {
		month?: string;
		day?: string;
		year?: string;
	};
	required?: boolean;
	disabled?: boolean;
	automationNameDay?: string;
	automationNameMonth?: string;
	automationNameYear?: string;
	automationError?: string;
	variant?: TextFieldProps['variant'];
}

const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const BottomErrorGrid = styled(Grid)`
	&& {
		padding-top: 0;
	}
`;

const validationCriteria = [minDateCriteria, maxDateCriteria, noLeadingZeroYearCriteria];

export const handleValidation = (isRequired: boolean, year: string, month: string, day: string) => (
	value: FormFields
) => {
	const { [FormNames.birthDate]: date } = value;

	const oneOrMoreMissing = R.any(R.anyPass([R.isNil, R.isEmpty]), [year, month, day]);
	const allMissing = R.all(R.anyPass([R.isNil, R.isEmpty]), [year, month, day]);

	// Show requied if one field is missing if the field is required, OR if one field is filled in
	if ((isRequired && (!date || oneOrMoreMissing)) || (oneOrMoreMissing && !allMissing)) {
		return requiredFieldMessage;
	}

	let result = '';

	if (!result) {
		result =
			validationCriteria
				.map(criteria => criteria(value))
				.find(criteriaResult => criteriaResult !== '') || '';
	}

	return result;
};

const digitRegExp = /^\d{0,4}$/;
const noneAreEmpty = R.none(R.isEmpty);

const EMPTY_VALUE: {
	month?: string;
	day?: string;
	year?: string;
} = {};

// API requires consistent # digits and non-index month
export const getApiDateString = (year, month, day) =>
	`${String(year).padStart(4, '0')}-${String(Number(month) + 1).padStart(2, '0')}-${String(
		day
	).padStart(2, '0')}`;

const BirthDateInput: FC<Props> = ({
	id,
	name = FormNames.birthDate,
	defaultValue = EMPTY_VALUE,
	label,
	required = false,
	disabled = false,
	automationNameDay = AutomationNameDefault.birthDay,
	automationNameMonth = AutomationNameDefault.birthMonth,
	automationNameYear = AutomationNameDefault.birthYear,
	automationError = AutomationNameDefault.birthError,
	variant = 'filled',
}) => {
	const { path } = useContext(SubFormContext);
	const { student } = useContext(StudentContext);
	const { getFormValues } = useContext(FormContext);
	const undefNullOrEmpty = R.anyPass([isNil, equals('')]);

	const bdayInStudentContext = [student.birthDay, student.birthMonth, student.birthYear].some(
		it => !undefNullOrEmpty(it)
	);
	const { [FormNames.birthDay]: birthDay } = getFormValues();
	const { [FormNames.birthMonth]: birthMonth } = getFormValues();
	const { [FormNames.birthYear]: birthYear } = getFormValues();

	const bdayInFormContext = [birthDay, birthMonth, birthYear].some(it => !undefNullOrEmpty(it));

	const updatedDisabled = disabled || (bdayInStudentContext && bdayInFormContext);

	const controlRef = useRef(null);

	const [year, setYear] = useState(defaultValue.year || '');
	const [month, setMonth] = useState(defaultValue.month || '');
	const [day, setDay] = useState(defaultValue.day || '');
	const validate = useMemo(() => handleValidation(required, year, month, day), [
		required,
		year,
		month,
		day,
	]);

	const initialValue = useMemo(() => {
		setYear(defaultValue.year || '');
		setMonth(defaultValue.month || '');
		setDay(defaultValue.day || '');

		return {
			[FormNames.birthDate]:
				defaultValue.year &&
				defaultValue.month &&
				defaultValue.day &&
				getApiDateString(defaultValue.year, defaultValue.month, defaultValue.day),
		};
	}, [defaultValue.day, defaultValue.month, defaultValue.year]);

	const { value, setValue, error, setError } = useFormValidation({
		path,
		name,
		initialValue,
		validator: validate,
		fieldRef: controlRef,
	});

	const [hasCompletedInput, setHasCompletedInput] = useState(false);

	const numDaysThisMonth = useMemo(() => {
		const daysThisMonth = new Date(
			year === null ? 2000 : Number(year),
			Number(month) + 1,
			0
		).getUTCDate();
		if (Number(day) > daysThisMonth) {
			setDay(undefined);
		}
		return daysThisMonth;
	}, [month, year, day]);

	const onYearChange = (e: ChangeEvent<HTMLInputElement>) => {
		// Disallow non-numeric input
		if (digitRegExp.test(e.target.value)) {
			setYear(e.target.value);
			setValue({
				[FormNames.birthDate]: getApiDateString(e.target.value, month, day || 1),
				[FormNames.birthYear]: e.target.value,
			});
		}
	};

	const onMonthChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setMonth(e.target.value);
		setValue({
			[FormNames.birthDate]: getApiDateString(year || 2000, e.target.value, day || 1),
		});
	};

	const onDayChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setDay(e.target.value);
		setValue({
			[FormNames.birthDate]: getApiDateString(year || 2000, month || 0, e.target.value),
		});
	};

	const onInputBlur = () => {
		if (noneAreEmpty([month, day, year]) || hasCompletedInput) {
			setHasCompletedInput(true);

			const validationError = validate(value);
			setError([validationError]);
		}
	};

	return (
		// Add flex-end to fix Safari input height issue.
		<Grid container spacing={1} alignItems="flex-end">
			<Grid item xs={12}>
				<Typography variant="h6" display="inline">
					{label}
				</Typography>
			</Grid>
			<Grid item xs={5}>
				<FormControl fullWidth variant={variant} ref={controlRef}>
					<InputLabel htmlFor={`${id}-month`} error={!!error} disabled={updatedDisabled}>
						Month
					</InputLabel>
					<Select
						native
						variant={variant}
						disabled={updatedDisabled}
						label="Month"
						name="month"
						id={`${id}-month`}
						inputProps={{
							'data-qa': automationNameMonth,
						}}
						onBlur={onInputBlur}
						onChange={onMonthChange}
						value={month}
						error={!!error}
					>
						<option value={null} />
						{R.range(0, 12).map(i => (
							<option key={i} value={i}>
								{MONTHS[i]}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={4}>
				<FormControl fullWidth variant={variant}>
					<InputLabel htmlFor={`${id}-day`} error={!!error} disabled={updatedDisabled}>
						Day
					</InputLabel>
					<Select
						native
						disabled={updatedDisabled}
						name="day"
						id={`${id}-day`}
						inputProps={{
							'data-qa': automationNameDay,
						}}
						onBlur={onInputBlur}
						onChange={onDayChange}
						value={day}
						error={!!error}
						variant={variant}
						label="Day"
					>
						<option value={null} />
						{R.range(1, numDaysThisMonth + 1).map(i => (
							<option key={i} value={i}>
								{i}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={3}>
				<FormControl fullWidth variant={variant}>
					<TextField
						id={`${id}-year`}
						name="year"
						type="text"
						value={year}
						disabled={updatedDisabled}
						inputProps={{
							pattern: '[12][0-9]{3,3}',
							'data-qa': automationNameYear,
						}}
						onBlur={onInputBlur}
						onChange={onYearChange}
						error={!!error}
						variant={variant}
						label="YYYY"
					/>
				</FormControl>
			</Grid>
			<BottomErrorGrid item xs={12}>
				<FormControl fullWidth variant={variant}>
					<FormHelperText error={!!error} data-qa={automationError}>
						{error}
					</FormHelperText>
				</FormControl>
			</BottomErrorGrid>
		</Grid>
	);
};

export default BirthDateInput;
