import React, { FC, useEffect, useContext, useState } from 'react';
import { QueueAction, ModalContext } from '@src/common/util/steps/components/ModalStepFlow';
import AuthRefresh from '@src/common/util/auth/components/AuthRefresh';
import { Navigate, useNavigate } from 'react-router-dom';
import { StepComponentProps } from '@src/common/util/steps';
import { DataFlowStepConfig } from '../../constants/types';
import AuthContext from '@src/common/util/auth';
import { env } from '@src/features/environment';
import { FormContext } from '@src/common/util/validation/form';
import { FormNames, DataFlowStepName } from '@cappex/constants';
import InquiryConsentModal from '@src/features/consent/components/InquiryConsentModal';
import canObtainConsent from '@src/features/consent/utils/canObtainConsent';
import { ConsentType } from '@src/features/consent/consentUtil';

// TODO: remove when CAPPEX-3554 is done
declare const newrelic: any;

const DoRedirectComponent = ({ complete, ...props }) => {
	useEffect(complete, [complete]);
	return <Navigate to={(props as any).to} />;
};

const RedirectStep: FC<StepComponentProps<any, any, DataFlowStepConfig>> = ({ data, active }) => {
	const { queueModal } = useContext(ModalContext);
	const { attemptedLocation } = useContext(AuthContext);
	const { getValue } = useContext(FormContext);
	const [checkedInquiryConsent, setCheckedInquiryConsent] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		active &&
			canObtainConsent(
				ConsentType.INQUIRY_CONSENT,
				res => {
					res.canObtainConsent &&
						queueModal(QueueAction.PREPEND, 'inquiry_consent_modal', InquiryConsentModal, {
							consentTypeId: res.consentTypeId,
							consentTextId: res.consentTextId,
							consentText: res.consentText,
							consentInputTypeId: res.consentInputTypeId,
						});
				},
				() => setCheckedInquiryConsent(true)
			);
	}, [active, queueModal]);

	useEffect(() => {
		checkedInquiryConsent &&
			queueModal(QueueAction.PREPEND, DataFlowStepName.Redirect, DoRedirectComponent, {
				to: attemptedLocation || (data as any).to,
			});
	}, [checkedInquiryConsent, queueModal, attemptedLocation, data]);

	const onAuthFail = () => {
		navigate('/login');

		// TODO: remove when CAPPEX-3554 is done
		if (env === 'prod') {
			const dataFlowCode = getValue(FormNames.dataFlowCode)[FormNames.dataFlowCode];
			const error = new Error(
				`Authentication error during redirect. DataFlowCode: ${dataFlowCode}`
			);

			if (typeof newrelic !== 'undefined') {
				newrelic.noticeError(error);
			} else {
				throw error;
			}
			throw error;
		}
	};

	return active ? <AuthRefresh onAuthFail={onAuthFail} /> : <div />;
};
export default RedirectStep;
