import * as R from 'ramda';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ReferenceDataTypes } from '@cappex/constants';
import { ErrorMessages } from '@cappex/request';
import { Grid, Typography } from '@material-ui/core';
import { styled } from '@cappex/theme';
import { DismissalType } from '@util/collegedismissal/CollegeDismissalConstants';
import dismissCollege from '../util/collegedismissal/util/CollegeDismissalUtil';
import { fitPreferenceMap, FitType } from '@util/fit/constants';
import useCloudReferenceData, { ReferenceData } from '../util/hooks/useCloudReferenceData';
import useStudentCollegePreferences from '../util/hooks/useStudentCollegePreferences';
import { OutreachContext, OutreachData } from '@util/outreach';
import { CollegePreferenceNames, LocationDataFields } from '@util/preferences/constants';
import {
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListTrackingType,
	StudentCollegeListTypeTrackingValue,
} from '@util/studentcollege/constants';
import StudentCollegeListContext from '@util/studentcollege/StudentCollegeListContext';
import GenericCardCarousel, { CardSkeletonSettings } from './GenericCardCarousel';
import OutreachCarouselCard, { OutreachCarouselCardProps } from './OutreachCarouselCard';
import OutreachEmptyState from './OutreachEmptyState';
import { SnackbarContext, SnackbarType } from './SnackbarManager';
import GenericCardGrid from '@common/components/GenericCardGrid';
import CollegeCardComponent from '@common/components/CollegeCard';

export enum LayoutVariant {
	GRID_VARIANT = 'GRID_VARIANT',
	CAROUSEL_VARIANT = 'CAROUSEL_VARIANT',
}

type CollegeOutreachProps = {
	hideHeaders?: boolean;
	cardsToShow?: number;
	trackingLocation: StudentCollegeListLocationTrackingValue;
	layoutVariant?: LayoutVariant;
	cardComponent?: React.ComponentType;
};

type AdditionalCollegeData = {
	collegeStudentBodySize: string;
	collegeTownSize: ReferenceData;
};

type PreferenceMappingData = {
	studentBodySizeData: ReferenceData[];
	townSizeData: ReferenceData[];
	locationData: string;
};

const EMPTY_ARRAY = [];

const GutterBottomGrid = styled(Grid)`
	padding-bottom: 0rem;
	padding-left: 0.75rem;
	margin-bottom: -0.75rem;
`;

const CardNumberGrid = styled(Grid)`
	padding: 0rem 0.75rem;
	margin-bottom: -0.75rem;
	margin-left: 0.5rem;
	border-radius: 5rem;
	background-color: ${props => props.theme.palette.primary.transparent};
`;

const ColoredTypography = styled(Typography)`
	color: ${props => props.theme.palette.ink.dark};
`;

const cardSkeletonSettings: CardSkeletonSettings = {
	variant: 'rect',
	width: '100%',
	height: '21.75rem',
};

const mapOutreachPrefs = (
	preference: string,
	collegeData: AdditionalCollegeData,
	mappingData: PreferenceMappingData
): string => fitPreferenceMap[preference]?.getMatchText(collegeData, mappingData);

const mapLocation = (city: ReferenceData, state: ReferenceData): string => {
	let location: string;

	if (city === null && state === null) {
		location = '';
	} else if (city === null) {
		location = state.value;
	} else if (state === null) {
		location = city.value;
	} else {
		location = city.value.concat(', ', state.value);
	}

	return location;
};

const createOutreachCardProps = (
	outreachData: OutreachData[],
	mappingData: PreferenceMappingData,
	onDismissOutreachCard: (collegeId: number) => void,
	onSaveOutreachCard: (collegeId: number) => void
): OutreachCarouselCardProps[] => {
	let outreachProps: OutreachCarouselCardProps[] = EMPTY_ARRAY;

	R.forEach((data: OutreachData) => {
		const additionalCollegeData: AdditionalCollegeData = {
			collegeTownSize: data.collegeTownSize,
			collegeStudentBodySize: data.collegeStudentBodySize,
		};

		const preferencesList = R.reject(
			(pref: string) => pref === undefined,
			R.map(
				(dataPref: string) => mapOutreachPrefs(dataPref, additionalCollegeData, mappingData),
				data.matchedCollegePreferences
					.filter(item => item.fitType !== FitType.COLLEGE_TYPE)
					.filter(item => item.subScore === 1)
					.map(item => item.fitType)
			)
		);

		const outreachProp: OutreachCarouselCardProps = {
			collegeId: data.collegeId,
			collegeName: data.collegeName,
			collegeHeroImage: data.collegeHeroImagePath,
			collegeLocation: mapLocation(data.collegeCity, data.collegeState),
			preferences: R.isEmpty(preferencesList) ? ['Great Fit for You'] : preferencesList,
			dismissCollege: onDismissOutreachCard,
			saveCollege: onSaveOutreachCard,
		};

		outreachProps = [...outreachProps, outreachProp];
	}, outreachData);

	return outreachProps;
};

const CollegeOutreach: FC<CollegeOutreachProps> = ({
	hideHeaders = false,
	trackingLocation,
	cardsToShow = 4,
	layoutVariant = LayoutVariant.CAROUSEL_VARIANT,
	cardComponent = OutreachCarouselCard,
}) => {
	const [outreachCardProps, setOutreachCardProps] = useState(EMPTY_ARRAY);
	const [outreachMatches, setOutreachMatches] = useState(0);
	const [isDataReady, setIsDataReady] = useState(false);
	const [isDataBeingFiltered, setIsDataBeingFiltered] = useState(false);
	const [isDataFiltered, setIsDataFiltered] = useState(false);

	const { openSnackbar } = useContext(SnackbarContext);
	const { outreachList, outreachDataRetrieved, noMatches, refresh, removeListItem } = useContext(
		OutreachContext
	);
	const { list, addListItem } = useContext(StudentCollegeListContext);

	const onError = useCallback(() => {
		openSnackbar({
			snackbarType: SnackbarType.Error,
			message: ErrorMessages.unknown,
		});
	}, [openSnackbar]);

	const studentLocationPreferences = useStudentCollegePreferences(onError)[
		CollegePreferenceNames.LOCATION
	];

	const [townSizeData] = useCloudReferenceData({
		dataType: ReferenceDataTypes.townSize,
	});

	const [studentBodySizeData] = useCloudReferenceData({
		dataType: ReferenceDataTypes.studentBodySize,
	});

	const [distanceData] = useCloudReferenceData({
		dataType: ReferenceDataTypes.distanceFromHome,
	});

	const showEmptyState = useMemo(() => isDataReady && R.isEmpty(outreachCardProps), [
		isDataReady,
		outreachCardProps,
	]);

	const locationData = useMemo(() => {
		if (
			!R.isNil(distanceData) &&
			!R.isEmpty(distanceData) &&
			!R.isNil(studentLocationPreferences) &&
			!R.isEmpty(studentLocationPreferences)
		) {
			// Instance where student doesn't have any location preferences
			if (
				R.isEmpty(studentLocationPreferences.studentPreferences) ||
				studentLocationPreferences.studentPreferences[0].type === LocationDataFields.stateIds
			) {
				return undefined;
			}

			const filterList = R.filter(
				(refData: ReferenceData) =>
					refData.id === studentLocationPreferences.studentPreferences[0].value,
				distanceData
			);

			return R.isEmpty(filterList) ? undefined : filterList[0].value;
		}

		return '';
	}, [distanceData, studentLocationPreferences]);

	const mappingData: PreferenceMappingData = useMemo(
		() => ({
			studentBodySizeData,
			townSizeData,
			locationData,
		}),
		[studentBodySizeData, townSizeData, locationData]
	);

	const reload = useCallback(() => {
		setIsDataReady(false);
		refresh();
	}, [refresh]);

	const onDismissOutreachCard = useCallback(
		(collegeId: number) => {
			dismissCollege(
				{
					collegeDismissalType: DismissalType.OUTREACH,
					collegeId,
				},
				onError,
				null
			).then((success: boolean) => {
				if (success) {
					removeListItem(collegeId);
				}
			});
		},
		[removeListItem, onError]
	);

	const onSaveOutreachCard = useCallback(
		(collegeId: number) => {
			addListItem({
				collegeId,
				trackingData: {
					[StudentCollegeListTrackingType.LOCATION]: trackingLocation,
					[StudentCollegeListTrackingType.TYPE]: StudentCollegeListTypeTrackingValue.OUTREACH,
				},
				onSuccess: () => {
					removeListItem(collegeId);
				},
			});
		},
		[removeListItem, addListItem, trackingLocation]
	);

	useEffect(() => {
		if (
			outreachDataRetrieved &&
			!R.isEmpty(mappingData.studentBodySizeData) &&
			!R.isEmpty(mappingData.townSizeData) &&
			!R.isEmpty(mappingData.locationData) &&
			isDataFiltered
		) {
			setOutreachMatches(outreachList.length);
			setOutreachCardProps(
				createOutreachCardProps(
					outreachList,
					mappingData,
					onDismissOutreachCard,
					onSaveOutreachCard
				)
			);

			setIsDataReady(true);
		}
	}, [
		isDataFiltered,
		outreachList,
		mappingData,
		outreachDataRetrieved,
		onDismissOutreachCard,
		onSaveOutreachCard,
	]);

	useEffect(() => {
		if (outreachDataRetrieved && !isDataBeingFiltered) {
			setIsDataBeingFiltered(true);

			if (!R.isEmpty(list)) {
				const filterList = R.intersection(
					R.map(R.prop('collegeId'))(list),
					R.map(R.prop('collegeId'))(outreachList)
				);

				if (!R.isEmpty(filterList)) {
					removeListItem(filterList[0]);
				}
			}

			setIsDataFiltered(true);
		}
	}, [outreachDataRetrieved, outreachList, list, isDataBeingFiltered, removeListItem]);

	return layoutVariant === LayoutVariant.CAROUSEL_VARIANT ? (
		<Grid container spacing={3}>
			{!hideHeaders && (
				<>
					<Grid item xs={12}>
						<Grid container spacing={3}>
							<GutterBottomGrid>
								<Typography variant="h6">Today&apos;s Matches</Typography>
							</GutterBottomGrid>
							{outreachMatches !== 0 && (
								<CardNumberGrid>
									<ColoredTypography variant="h6">{outreachMatches}</ColoredTypography>
								</CardNumberGrid>
							)}
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1" color="textSecondary">
							Like or dismiss to improve your results.
						</Typography>
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				{showEmptyState ? (
					<OutreachEmptyState useInteractionEmptyState={!noMatches} onClick={reload} />
				) : (
					<GenericCardCarousel
						maxCardsInCarousel={10}
						maxCardsVisible={cardsToShow}
						cardSkeletonSettings={cardSkeletonSettings}
						cardComponenet={cardComponent}
						cardData={outreachCardProps}
						centerMode={false}
					/>
				)}
			</Grid>
		</Grid>
	) : (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				{showEmptyState ? (
					<OutreachEmptyState useInteractionEmptyState={!noMatches} onClick={reload} />
				) : (
					<GenericCardGrid
						maxCardsInGrid={4}
						cardSkeletonSettings={cardSkeletonSettings}
						cardComponenet={CollegeCardComponent}
						cardData={outreachCardProps}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default CollegeOutreach;
