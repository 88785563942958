import React, { FC, useContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import WebFont from 'webfontloader';
import LoggedInContainer from '@common/components/LoggedInContainer';
import { styled } from '@cappex/theme';
import GenericLinkExpired from '@src/common/components/GenericLinkExpired';
import LoadingComponent from '@src/common/components/LoadingComponent';
import SnackbarManager from '@src/common/components/SnackbarManager';
import GATracking from '@src/common/util/analytics/googleAnalytics';
import SmartScriptTracking from '@src/common/util/analytics/smartscriptAnalytics';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import OutreachProvider from '@src/common/util/outreach/components/OutreachProvider';
import ModalManager from '@src/common/util/steps/components/ModalStepFlow';
import StudentCollegeListProvider from '@src/common/util/studentcollege/components/StudentCollegeListProvider';
import LoadableAccountSuppression from '@src/features/accountsuppression';
import LoadableGenericNotFound from '@src/features/app/components/LoadableNotFound';
import LoadableCollegeListPage from '@src/features/collegelist';
import LoadableCollegePlannerPage from '@src/features/collegeplanner';
import LoadableCollegePreferencesPage from '@src/features/collegepreferences';
import LoadableCollegeProfilePage from '@src/features/collegeprofile';
import LoadableCollegeSearchLandingPage from '@src/features/collegesearch';
import LoadableDashboardPage from '@src/features/dashboard';
import LoadableMyAdmissionOffersPage from '@src/features/matchdirect';
import LoadableOfferPage from '@src/features/offer';
import ForgotPasswordContainer from '@src/features/forgotpassword';
import InviteManager from '@src/features/invite/containers/InviteManager';
import LoginContainer from '@src/features/login';
import LoadableProfilePage from '@src/features/profile';
import BASE_PROFILE_PATH from '@src/features/profile/constants/constants';
import RegistrationFlow from '@src/features/registration/containers/RegistrationFlow';
import ResetPasswordContainer from '@src/features/resetpassword';
import LoadableScholarshipSearchLandingPage from '@src/features/scholarshipsearch';
import LoadableSettingsPage from '@src/features/settings';
import StudentProvider from '@util/studentContext/components/StudentProvider';
import TrackingManager from './TrackingManager';
import OfferCardModalIframe from '@src/features/offer/components/OfferCardModalIframe';
import AcceptedOffersProvider from '@util/acceptedofferscontext/components/AcceptedOffersProvider';
import AchievementsPage from '@src/features/incentivization/main';
import { enableIncentivization } from '@src/features/environment';
import MagicLinkPage from '@src/features/magiclogin';
import HeapTracking from '@src/common/util/analytics/heapAnalytics';
import SnowplowTracker from './SnowplowTracker';
import useReloadCheck from '../util/useReloadCheck';
import EventBusManager from './EventBusManager';
import AppErrorBoundary from './AppErrorBoundary';

// Bring in and use our main font
WebFont.load({
	google: {
		families: ['Nunito Sans:400,700&display=swap'],
	},
});

const FontDiv = styled.div`
	font-family: '"Nunito Sans"', serif;
`;

const LoggedInComponent = () => {
	const { search } = useLocation();

	return (
		<LoggedInContainer>
			<InviteManager />
			<Routes>
				<Route path="/dashboard" element={<LoadableDashboardPage />} />
				<Route path="/college-list" element={<LoadableCollegeListPage />} />
				<Route path="/college-search" element={<LoadableCollegeSearchLandingPage />} />
				<Route path="/college-planner/" element={<LoadableCollegePlannerPage />} />
				<Route path="/college-planner/:categoryId/" element={<LoadableCollegePlannerPage />} />
				<Route
					path="/college-planner/:categoryId/:actionId"
					element={<LoadableCollegePlannerPage />}
				/>
				<Route path="/match-direct" element={<LoadableMyAdmissionOffersPage />} />
				<Route path="/scholarship-search" element={<LoadableScholarshipSearchLandingPage />} />
				<Route path="/college-preferences" element={<LoadableCollegePreferencesPage />} />
				<Route path="/college-profile/:id/:slug/*" element={<LoadableCollegeProfilePage />} />
				<Route path={BASE_PROFILE_PATH} element={<LoadableProfilePage />} />
				<Route path="/settings" element={<LoadableSettingsPage />} />
				<Route path="/suppress-account" element={<LoadableAccountSuppression />} />
				<Route path="/offers" element={<LoadableOfferPage />} />
				{enableIncentivization && <Route path="/achievements" element={<AchievementsPage />} />}
				<Route path="*" element={<Navigate to={{ pathname: '/dashboard', search }} />} />
			</Routes>
		</LoggedInContainer>
	);
};

const getLoggedInRoutes = () => (
	<StudentProvider key="logged-in">
		<StudentCollegeListProvider>
			<OutreachProvider>
				<AcceptedOffersProvider>
					<Routes>
						<Route path="/offer/pa/modal" element={<OfferCardModalIframe />} />
						<Route path="/*" element={<LoggedInComponent />} />
					</Routes>
				</AcceptedOffersProvider>
			</OutreachProvider>
		</StudentCollegeListProvider>
	</StudentProvider>
);

const getRegIncompleteRoutes = () => (
	<StudentProvider key="reg-incomplete">
		<Routes>
			<Route path="/register/:pathCode/:pathVersion/*" element={<RegistrationFlow />} />
			<Route path="/forgot-password" element={<ForgotPasswordContainer />} />
			<Route path="/reset-password" element={<ResetPasswordContainer />} />
			<Route
				path="/expired-link"
				element={<GenericLinkExpired to="/login" redirectLabel="Back to Login" />}
			/>
			<Route path="/suppress-account" element={<LoadableAccountSuppression />} />
			<Route path="*" element={<Navigate to="/register/core/main1" />} />
		</Routes>
	</StudentProvider>
);

const getLoggedOutRoutes = () => (
	<StudentProvider key="logged-out">
		<Routes>
			<Route path="/register/:pathCode/:pathVersion/*" element={<RegistrationFlow />} />
			<Route path="/magic-login" element={<MagicLinkPage />} />
			<Route path="/login" element={<LoginContainer />} />
			<Route path="/forgot-password" element={<ForgotPasswordContainer />} />
			<Route path="/reset-password" element={<ResetPasswordContainer />} />
			<Route
				path="/expired-link"
				element={<GenericLinkExpired to="/login" redirectLabel="Back to Login" />}
			/>
			<Route path="*" element={<Navigate to="/login" />} />
			<Route element={<LoadableGenericNotFound />} />
		</Routes>
	</StudentProvider>
);

const getUnknownAuthRoute = () => (
	<Routes>
		<Route path="*" element={<LoadingComponent />} />
	</Routes>
);

const getRoutes = (authState: AuthenticState, registrationIsComplete: boolean) => {
	switch (authState) {
		case AuthenticState.Authentic:
			return registrationIsComplete ? getLoggedInRoutes() : getRegIncompleteRoutes();
		case AuthenticState.NotAuthentic:
			return getLoggedOutRoutes();
		case AuthenticState.Unknown:
		default:
			return getUnknownAuthRoute();
	}
};

const App: FC = () => {
	const { isAuthentic, registrationIsComplete } = useContext(AuthContext);
	useReloadCheck();
	return (
		<AppErrorBoundary>
			<FontDiv>
				<GATracking />
				<SmartScriptTracking />
				<SnowplowTracker />
				<HeapTracking />
				<TrackingManager />
				<SnackbarManager>
					<ModalManager>
						<EventBusManager />
						{getRoutes(isAuthentic, registrationIsComplete)}
					</ModalManager>
				</SnackbarManager>
			</FontDiv>
		</AppErrorBoundary>
	);
};
export default App;
